import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";
import Ecart from "../../Assets/Projects/ecarts.png";
 

import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          Our Recent <strong className="Fluorescent-Blue">Work </strong>
        </h1>
        <p>Here are a  project We've worked on recently.</p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Ecart}
              title="Student Bazaar"
              description="This is a fully functional eCommerce website that uses React.js + tailwind CSS in the front end and NodeJs and express js in backend ."
              ghLink="https://github.com/gkm0001/E-commerce"
              demoLink="https://www.studentBazaar.in/"
            />
          </Col>
 
        </Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}

export default Projects;

import React from "react";
import Card from "react-bootstrap/Card";
import { AiOutlineArrowRight } from "react-icons/ai";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0" data-aos="zoom-in">
          <p style={{ textAlign: "justify"}}>
            Hi Everyone, We are  <span className="primary-header">WebBlitz</span> from India. We provide support to startups and individuals.
            <br />
            <br />
              Here are some other things We enjoy doing!
          </p>
          <ul>
            <li className="about-activity">
              <AiOutlineArrowRight /> Frontend Development
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Backend Development
            </li>
            <li className="about-activity">
              <AiOutlineArrowRight /> Devops
            </li>
             
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
